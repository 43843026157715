import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { AdEditorAiPayload, AdVariant } from 'types/adEditorTypes';
import { AdChannel, AdCreativeType, AdPlacement } from 'types/adTypes';
import { useImmer } from 'use-immer';
import { AdEditorSideBarTabs } from 'views/ad-builder/AdBuilder';
import { Stack } from 'immutable';
import useNavigationContext from 'hooks/context/nav-context';
import { deleteTspan, getOverlay } from 'utils/overlayHelpers';
import { fabric } from 'fabric';
import { getCanvasHeight, getCanvasWidth } from 'utils/adEditorCanvasHelpers';
import { uniqueId } from 'lodash';
import { Crop } from 'react-image-crop';
import { AdOverlay } from 'types/adEditorTypes';
import { useQuery } from 'hooks/sympl-query';
import {
  GET_CUSTOM_AD_OVERLAYS,
  GET_DEFAULT_AD_OVERLAYS,
} from 'graphql/overlays/queries';
import useDebouncedCallback from 'hooks/debounceCallback';

type AdEditorContextType = {
  activeTab: AdEditorSideBarTabs | undefined;
  currentVariant: AdVariant | undefined;
  payload: AdEditorAiPayload | undefined;
  previewChannel: AdChannel | undefined;
  defaultAdOverlays: AdOverlay[];
  customAdOverlays: AdOverlay[];
  setActiveTab: Dispatch<SetStateAction<AdEditorSideBarTabs | undefined>>;
  setPayload: Dispatch<SetStateAction<AdEditorAiPayload | undefined>>;
  setPreviewChannel: Dispatch<SetStateAction<AdChannel | undefined>>;
  setUploadMode: Dispatch<SetStateAction<boolean>>;
  initVariants: (variants: AdVariant[]) => void;
  deleteCurrentVariant: () => void;
  addVariantForPlacement: (placement: AdPlacement) => void;
  duplicateCurrentVariant: () => void;
  addVariant: (variant: AdVariant) => void;
  setCurrentVariant: (variant: AdVariant) => void;
  updateCurrentVariant: (
    key: keyof AdVariant,
    value: any,
    setIsDirty?: boolean
  ) => void;
  uploadMode: boolean;
  variants: AdVariant[];
  videoRef: React.RefObject<HTMLVideoElement>;
  svgStack: Stack<string>;
  addCanvasElement: (type: string) => void;
  setCanvas: (canvas: fabric.Canvas | null) => void;
  undoChange: () => void;
  changeOverlay: (overlay: string) => void;
  canvasWidth: number;
  canvasHeight: number;
  getCanvasObject: (str: 'bg' | 'logo') => fabric.Object | undefined;
  activeObject: fabric.Object | undefined;
  updateActiveObject: (
    key: any, // TODO: keyof fabric.Object | fabric.Text | fabric.Textbox,
    value: any
  ) => void;
  removeActiveObject: () => void;
  duplicateActiveObject: () => void;
  cropBackground: (crop: Crop | null) => void;
  sendBackwards: () => void;
  bringForward: () => void;
};

export const AdEditorContext = createContext<AdEditorContextType>(
  {} as AdEditorContextType
);

const DEFAULT_FABRIC_PROPS = {
  hoverCursor: 'pointer',
  objectCaching: false,
  transparentCorners: false,
  cornerSize: 25,
  borderScaleFactor: 6,
  selectable: true,
  editable: true,
};

export const AdEditorProvider: React.FC<ReactNode> = ({ children }) => {
  const [activeTab, setActiveTab] = useState<AdEditorSideBarTabs | undefined>(
    AdEditorSideBarTabs.VISUAL_OPTIONS
  );
  const [payload, setPayload] = useState<AdEditorAiPayload>();
  const [previewChannel, setPreviewChannel] = useState<AdChannel>();
  const [variants, setVariants] = useImmer<AdVariant[]>([]);
  const [uploadMode, setUploadMode] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [svgStack, setSvgStack] = useState(Stack<string>());
  const [defaultAdOverlays, setDefaultAdOverlays] = useState<AdOverlay[]>([]);
  const [customAdOverlays, setCustomAdOverlays] = useState<AdOverlay[]>([]);
  const serializer = new XMLSerializer();

  const [canvas, setCanvas] = useState<fabric.Canvas | null>(null);

  const [activeObject, _setActiveObject] = useState<fabric.Object>();

  const { currentVacancy, customer } = useNavigationContext();

  const [currentVariant, setCurrentVariant] = useState<AdVariant>();

  useQuery<{
    defaultOverlays: AdOverlay[];
  }>(GET_DEFAULT_AD_OVERLAYS, {
    onCompleted: (data) => setDefaultAdOverlays(data.defaultOverlays),
  });

  useQuery<{
    customOverlays: AdOverlay[];
  }>(GET_CUSTOM_AD_OVERLAYS, {
    onCompleted: (data) => setCustomAdOverlays(data.customOverlays),
    skip: !customer?.has_custom_overlays,
  });

  const duplicateCurrentVariant = () => {
    if (!currentVariant) return;
    addVariant({
      ...currentVariant,
      id: undefined,
      isDeleted: false,
      isDirty: true,
      uuid: uniqueId(),
    });
  };

  const initVariants = (variants: AdVariant[]) => {
    setVariants(variants);

    const matchedVariant = variants.find(({ id }) => id === currentVariant?.id);
    const samePlacementVariant = variants.find(
      ({ placement }) => placement === currentVariant?.placement
    );

    // Set old currentVariant or pick one with the same placement or first one from the list
    setCurrentVariant(matchedVariant || samePlacementVariant || variants[0]);
  };

  // Set preview channel to first selected channel if not set
  useEffect(() => {
    if (previewChannel) return;

    const fallbackChannel = currentVacancy?.channels.length
      ? currentVacancy?.channels[0]
      : AdChannel.FACEBOOK;

    setPreviewChannel(fallbackChannel);
  }, [previewChannel, currentVacancy]);

  const canvasWidth = useMemo(
    () => getCanvasWidth(currentVariant?.placement),
    [currentVariant?.placement]
  );

  const canvasHeight = useMemo(
    () => getCanvasHeight(currentVariant?.placement),
    [currentVariant?.placement]
  );

  const getCanvasObject = (str: 'bg' | 'logo') =>
    canvas?.getObjects('image')[str === 'bg' ? 0 : 1];

  const cropBackground = (crop: Crop | null) => {
    const background = getCanvasObject('bg') as fabric.Image;
    if (!background || !crop) return;

    background.width = currentVariant?.path?.width;
    background.height = currentVariant?.path?.height;

    const { width, height, x, y } = crop;
    const scaleX = canvasWidth / (background.width! * (width / 100));
    const scaleY = canvasHeight / (background.height! * (height / 100));

    Object.assign(background, {
      left: -(background.width! * (x / 100) * scaleX),
      top: -(background.height! * (y / 100) * scaleY),
      scaleX,
      scaleY,
      clipPath: undefined,
      cropX: 0,
      cropY: 0,
      preserveAspectRatio: '',
    });

    // Manually trigger event, since it is not picked up automatically
    triggerCanvasEvent();

    canvas?.requestRenderAll();
  };

  const updateCurrentVariant = (key: keyof AdVariant, value: any) => {
    if (key === 'svg') setSvgStack((prev) => prev.push(currentVariant?.svg!));

    const updatedVariants = variants.map((variant) => {
      if (variant !== currentVariant) return variant;

      (variant[key] as any) = value;
      variant.isDirty = true;

      if (key === 'path') {
        variant.creative_type = value?.type;

        if (value?.type === 'video') {
          variant.logo = null;
          variant.svg = null;
        }

        if (value === null) {
          variant.svg = null;
        }
      }

      /**
      if (key === 'svg') {
        // 1. Get all image objects
        const images = canvas
          ?.getObjects()
          .filter(({ type }) => type === 'image') as fabric.Image[];

        // 2. Inspect each underlying HTMLImageElement
        images.forEach((imgObj) => {
          const htmlImg = imgObj.getElement() as HTMLImageElement;
          console.log(htmlImg.src, ' - ', htmlImg.crossOrigin);
        });

        try {
          variant.data_url = canvas?.toDataURL({
            format: 'jpeg',
          });
        } catch (error) {
          console.error(error);
        }
      }
         */

      return variant;
    });

    setVariants(updatedVariants);

    if (key === 'logo') addCanvasElement('logo');

    if (key === 'path' && currentVariant?.path?.type === 'image') {
      if (currentVariant?.svg) {
        const background = getCanvasObject('bg') as fabric.Image | undefined;

        // Set the new source for the background image
        background?.setSrc(
          currentVariant?.path?.path ?? '',
          () => {
            const scale = Math.max(
              canvasWidth / background.width!,
              canvasHeight / background.height!
            );

            Object.assign(background, {
              scaleX: scale,
              scaleY: scale,
              left: (canvasWidth - background.width! * scale) / 2,
              top: (canvasHeight - background.height! * scale) / 2,
            });

            // Manually trigger event, since it is not picked up automatically
            triggerCanvasEvent();

            // Trigger a render update
            canvas?.requestRenderAll();
          }
          // { crossOrigin: 'anonymous' }
        );
      } else {
        changeOverlay(defaultAdOverlays[0].svg);
      }
    }

    if (key === 'isDeleted' && value === true) {
      const nonDeteledVariant = variants.find(({ isDeleted }) => !isDeleted);
      if (nonDeteledVariant) setCurrentVariant(nonDeteledVariant);

      // Empty undo stack when switching between variants
      setSvgStack(Stack<string>());

      // Close side bar when switching variant
      setActiveObject(undefined);
    }

    return;
  };

  const deleteCurrentVariant = () => updateCurrentVariant('isDeleted', true);

  const addVariant = (variant: AdVariant) => {
    setVariants([...variants, variant]);
    setCurrentVariant(variant);
  };

  // TODO: based on old approach, can be cleaner
  const addVariantForPlacement = (placement: AdPlacement) => {
    const referenceVariant =
      variants.find(({ placement: p }) => p === placement) ?? variants[0];

    const bannerTitle = currentVacancy?.brand?.default_banner_title ?? 'WANTED';
    const city = currentVacancy?.targeting?.locations?.[0]?.city;

    addVariant({
      ...referenceVariant,
      id: undefined,
      path: null,
      logo: null,
      ...(placement === AdPlacement.REELS && {
        text: city
          ? `${bannerTitle}: ${currentVacancy?.title} in ${city}`
          : `${bannerTitle}: ${currentVacancy?.title}`,
      }),
      svg: null,
      isDeleted: false,
      isDirty: true,
      placement,
      creative_type:
        placement === AdPlacement.REELS
          ? AdCreativeType.VIDEO
          : AdCreativeType.IMAGE,
      uuid: uniqueId(),
    });
  };

  const changeOverlay = (ov: string) => {
    if (!canvas) return;
    const location = currentVacancy?.targeting?.locations?.[0]?.city;
    const overlay = getOverlay(
      ov,
      currentVariant,
      currentVacancy?.brand,
      location
    );

    (async () => await setCanvasTo(overlay))();
  };

  /**
   * Read an SVG Image
   * @param svg => the image in svg format
   *
   */

  const setCanvasTo = async (svg: string) => {
    if (!canvas || !currentVariant) return;

    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svg, 'image/svg+xml');

    //Remove the rectangle (the rectangle is normally the background color of the text)
    //Set the background color of the object fabric.Text
    //&& get preview image (Placement menu)
    svgDoc.querySelectorAll('g').forEach((group) => {
      const rect = group.querySelectorAll('rect');
      const text = group.querySelector('text');

      if (rect[0] && text) {
        const bgColor = rect[rect.length - 1].getAttribute('fill');
        if (!bgColor) return;
        text.setAttribute('style', `data-bg-color: ${bgColor}`);
        rect.forEach((v) => group.removeChild(v));
      }
    });

    //Old variants do still have tspan in their SVG -> remove them!
    const svgContent = serializer.serializeToString(svgDoc.documentElement);
    if (svgContent.includes('tspan'))
      svgDoc.querySelectorAll('g > text').forEach((txt) => {
        const originalSvg = serializer.serializeToString(txt.parentNode!);
        const transformedSvg = deleteTspan(originalSvg);

        const parent = txt.parentNode;
        if (parent && parent.parentNode) {
          const parser = new DOMParser();
          const doc = parser.parseFromString(transformedSvg, 'image/svg+xml');
          const newG = doc.documentElement;
          parent.parentNode.replaceChild(newG, parent);
        }
      });

    const ctx = canvas.getContext();
    if (ctx) {
      canvas.clearContext(ctx);
      canvas.clear();
    }

    await (async () => {
      try {
        const objects = await new Promise<fabric.Object[]>(
          (resolve, reject) => {
            fabric.loadSVGFromString(
              serializer.serializeToString(svgDoc.documentElement),
              (objects) => {
                if (objects) resolve(objects);
                else reject(new Error('Reading SVG failed'));
              }
              /**
              (_: SVGElement, obj: fabric.Object) => {
                // reviver callback
                (obj as fabric.Image).crossOrigin = 'anonymous';
              }
              */
            );
          }
        );

        objects.forEach((v, i) => {
          v.set({ ...DEFAULT_FABRIC_PROPS });

          // Make sure the background image is not moveable
          if (i === 0 && v.type === 'image')
            (v as fabric.Image).set({
              lockMovementX: true,
              lockMovementY: true,
              lockScalingX: true,
              lockScalingY: true,
              lockRotation: true,
              hasControls: false,
              selectable: false,
            });

          if (v.type === 'text') {
            const textB = v as fabric.Text;
            textB.set({
              text: textB.text!.replaceAll('{NEW_LINE_BREAK}', '\n'),
              textLines: textB.text!.includes('{NEW_LINE_BREAK}')
                ? textB.textLines
                : [textB.text!],
            });

            const width1 = textB.getBoundingRect().width + 1;
            const width2 = textB.textLines.reduce(
              (max, _, i) => Math.max(max, textB.getLineWidth(i)),
              0
            );
            textB.set({
              scaleX: 1,
              scaleY: 1,
              width:
                textB.textLines.length > 1 ? width1 : Math.max(width1, width2),
            });
          }

          // Needed for canvas.getDataUrl()
          /**
          if (v.type === 'image') {
            const imageObj = v as fabric.Image;

            // Original URL
            const originalSrc = imageObj.getSrc();

            // Force a re-fetch with crossOrigin
            imageObj.setSrc(
              originalSrc,
              () => {
                canvas.requestRenderAll();
              },
              { crossOrigin: 'anonymous' }
            );
          }
          */

          canvas.add(v);
        });

        canvas.requestRenderAll();
      } catch (error) {
        console.error('Error:', error);
      }
    })();

    if (svg !== currentVariant.svg) canvas.fire('object:modified');
  };

  const undoChange = () => {
    const topItem = svgStack.first();
    if (topItem) setCanvasTo(topItem).then(() => setSvgStack(svgStack.pop()));
  };

  // Set canvas when currentVariant changes
  useEffect(() => {
    if (currentVariant?.svg) setCanvasTo(currentVariant.svg);
  }, [canvas, currentVariant]);

  const addToCanvas = (element: fabric.Object) => {
    canvas?.add(element);
    canvas?.setActiveObject(element);
    triggerCanvasEvent();
  };

  const MAX_LOGO_HEIGHT = 120;
  const MAX_LOGO_WIDTH = 150;

  const changeLogo = () => {
    if (!canvas) return;

    // Remove old logo
    const object = getCanvasObject('logo');
    if (object) {
      canvas?.remove(object);
      triggerCanvasEvent();
    }

    // Add new logo (if any present)
    const logo = currentVariant?.logo?.path;
    if (logo) {
      fabric.Image.fromURL(
        logo,
        (img) => {
          const ratio = img.width! / img.height!;

          const scale =
            ratio < 1
              ? MAX_LOGO_WIDTH / img.width!
              : MAX_LOGO_HEIGHT / img.height!;

          img.set({
            ...DEFAULT_FABRIC_PROPS,
            top: 25,
            left: canvasWidth - 25 - img.width! * scale,
          });

          img.scale(scale);

          addToCanvas(img);
        }
        // { crossOrigin: 'anonymous' }
      );
    }
  };

  const addCanvasElement = (type: string) => {
    switch (type) {
      case 'text':
        addToCanvas(
          new fabric.Text('New Text', {
            ...DEFAULT_FABRIC_PROPS,
            fontFamily: 'Arial',
            fontSize: 56,
            fontWeight: 700,
            textAlign: 'left',
            fill: '#000000',
            backgroundColor: 'transparent',
            type: 'text',
            centeredScaling: false,
          })
        );
        break;
      case 'rectangle':
        addToCanvas(
          new fabric.Rect({
            ...DEFAULT_FABRIC_PROPS,
            width: 400,
            height: 150,
            fill: '#ffffff',
            type: 'rect',
          })
        );
        break;
      case 'logo':
        changeLogo();
        break;
      default:
        break;
    }
  };

  const triggerCanvasEvent = useDebouncedCallback(() => {
    canvas?.fire('object:modified');
  }, 500);

  const updateActiveObject = (key: keyof fabric.Object, value: any) => {
    const active = canvas?.getActiveObject();
    if (!active) return;

    active.set({ [key]: value });

    // Clone the object to ensure React detects a state change
    const updatedActiveObject = Object.assign(
      Object.create(Object.getPrototypeOf(active)),
      active
    );

    triggerCanvasEvent();
    setActiveObject(updatedActiveObject ?? undefined);
  };

  const removeActiveObject = () => {
    if (!activeObject) return;

    canvas?.remove(activeObject);
    triggerCanvasEvent();

    setActiveObject(undefined);
  };

  const duplicateActiveObject = () => {
    if (!activeObject) return;

    activeObject.clone((clonedObject: fabric.Object) => {
      // Offset the new object slightly to distinguish it visually
      clonedObject.set({
        left: activeObject.left! + 40,
        top: activeObject.top! + 40,
      });

      addToCanvas(clonedObject);
    });
  };

  const setActiveObject = (object?: fabric.Object) => {
    _setActiveObject(object);

    if (object) {
      // Timeout added so clicking an element doesn't become dragging when the sidebar opens
      setTimeout(() => {
        switch (object.type) {
          case 'text':
            setActiveTab(AdEditorSideBarTabs.TEXT_OPTIONS);
            break;
          case 'image':
            setActiveTab(AdEditorSideBarTabs.VISUAL_OPTIONS);
            break;
          default:
            setActiveTab(AdEditorSideBarTabs.SHAPE_OPTIONS);
            break;
        }
      }, 250);
    } else {
      canvas?.discardActiveObject();
      setActiveTab(undefined);
    }

    canvas?.requestRenderAll(); // Needed for resetting "active" border
  };

  useEffect(() => {
    canvas?.on('object:modified', () => {
      updateCurrentVariant('svg', canvas?.toSVG());
    });

    const handleSelectionChange = () => {
      // Multi-select measure
      if (canvas?.getActiveObjects()?.length! > 1) {
        setActiveTab(AdEditorSideBarTabs.VISUAL_OPTIONS);
        return;
      } else {
        setActiveObject(canvas?._activeObject);
      }
    };

    canvas?.on('selection:created', handleSelectionChange);
    canvas?.on('selection:updated', handleSelectionChange);
    canvas?.on('selection:cleared', handleSelectionChange);

    const handleClickOutside = (event: MouseEvent) => {
      if (event.target === document.getElementById('container'))
        setActiveObject(undefined);
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      canvas?.off('object:modified');
      canvas?.off('selection:created');
      canvas?.off('selection:updated');
      canvas?.off('selection:cleared');

      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [canvas, currentVariant]);

  const sendBackwards = () => {
    activeObject?.sendBackwards();
    triggerCanvasEvent();
  };

  const bringForward = () => {
    activeObject?.bringForward();
    triggerCanvasEvent();
  };

  return (
    <AdEditorContext.Provider
      value={{
        setCanvas,
        activeTab,
        currentVariant,
        defaultAdOverlays,
        customAdOverlays,
        payload,
        previewChannel,
        setActiveTab,
        setPayload,
        setPreviewChannel,
        setUploadMode,
        initVariants,
        deleteCurrentVariant,
        addVariant,
        addVariantForPlacement,
        duplicateCurrentVariant,
        updateCurrentVariant,
        setCurrentVariant,
        uploadMode,
        variants,
        videoRef,
        svgStack,
        addCanvasElement,
        undoChange,
        changeOverlay,
        canvasWidth,
        canvasHeight,
        getCanvasObject,
        activeObject,
        updateActiveObject,
        removeActiveObject,
        duplicateActiveObject,
        cropBackground,
        sendBackwards,
        bringForward,
      }}
    >
      {children}
    </AdEditorContext.Provider>
  );
};
