// DOCS: https://developers.intercom.com/installing-intercom/docs/intercom-javascript

const defaultConfig = {
  app_id: import.meta.env.VITE_INTERCOM_ID,
};

export const initIntercom = (alignment = 'right') => {
  (window as any).Intercom('boot', {
    ...defaultConfig,
    alignment,
  });
};

export const identifyIntercom = (email: string) => {
  (window as any).Intercom('update', {
    ...defaultConfig,
    email,
    id: email,
  });
};

export const showIntercom = (message?: string) => {
  (window as any).Intercom('update', { hide_default_launcher: false });
  (window as any).Intercom('showNewMessage', message);
};

export const showIntercomHome = () => {
  (window as any).Intercom('update', { hide_default_launcher: false });
  (window as any).Intercom('show');
};

export const hideIntercom = () => {
  (window as any).Intercom('hide');
  (window as any).Intercom('update', { hide_default_launcher: true });
};
